<template>
  <el-table
    :data="filteredReport7.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column
      align="center"
      width="180"
      prop="start_date2"
      label="Chiqish kuni"
    >
      <template slot-scope="scope">
        {{ $dayjs(scope.row.start_date2).format('DD-MM-YYYY') }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="180"
      prop="client"
      label="Buyurtmachi kompaniya"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_model"
      label="Mashina"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_nomer"
      label="Gos. nomer"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_tonnaj"
      label="Tonnaj"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_volume"
      label="M3"
    />

    <el-table-column
      align="center"
      width="180"
      prop="post_kg"
      label="заказной тон/кг"
      ><template slot-scope="scope">
        {{ scope.row.post_kg | toRoundFloat }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="180"
      prop="from_city"
      label="Пункт отправки"
    />

    <el-table-column
      align="center"
      width="180"
      prop="to_city1"
      label="Пункт назначения 1"
    />

    <el-table-column
      align="center"
      width="180"
      prop="to_city2"
      label="Пункт назначения 2"
    />
    <el-table-column
      align="center"
      width="180"
      prop="to_city3"
      label="Пункт назначения 3"
    />
    <el-table-column
      align="center"
      width="180"
      prop="to_city4"
      label="Пункт назначения 4"
    />
    <el-table-column align="center" width="100" label="BTS">
      <el-table-column align="center" width="180" prop="price" label="Сумма">
        <template slot-scope="scope">
          {{ scope.row.price | toRoundFloat }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="180" prop="nds_summa" label="НДС"
        ><template slot-scope="scope">
          {{ scope.row.nds_summa | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="180"
        prop="price_with_nds"
        label="Сумма с НДС"
        ><template slot-scope="scope">
          {{ scope.row.price_with_nds | toRoundFloat }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Поставщика">
      <el-table-column
        align="center"
        width="180"
        prop="postavshik_price"
        label="Сумма"
      >
        <template slot-scope="scope">
          {{ scope.row.postavshik_price | toRoundFloat }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="postavshik_nds_summa"
        label="НДС"
        ><template slot-scope="scope">
          {{ scope.row.postavshik_nds_summa | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="180"
        prop="postavshik_price_with_nds"
        label="Сумма с НДС"
        ><template slot-scope="scope">
          {{ scope.row.postavshik_price_with_nds | toRoundFloat }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column
      align="center"
      width="180"
      prop="zayavka_info"
      label="Yuk malumoti"
    />

    <el-table-column align="center" width="180" prop="post_m3" label="Hajm" />

    <el-table-column
      align="center"
      width="180"
      prop="deliver"
      label="Поставщик"
    />

    <el-table-column
      align="center"
      width="180"
      prop="ttn_number"
      label="ТТНК #"
    />

    <el-table-column
      align="center"
      width="180"
      prop="zayavka_distance"
      label="км"
    />
    <el-table-column align="center" width="100" label="BTS Haydovchisi">
      <el-table-column
        align="center"
        width="180"
        prop="bts_main_driver"
        label="Asosiy"
      />

      <el-table-column
        align="center"
        width="180"
        prop="bts_assistant_driver"
        label="Yordamchi"
      />
    </el-table-column>

    <el-table-column
      align="center"
      width="180"
      prop="postavshik_driver"
      label="Postavshik Haydovchi"
    />
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport7'])
  },

  filters: {
    toRoundFloat
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Reyslar ro\'yxati' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
